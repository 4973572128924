:root {
  --screen-height: 100px;
  --screen-width: 100px;
  --cell-size: 100px;
  --board-width: 100px;
}

html,
body {
  background-color: #dded9d;
  /* background-color: #98ddd525; */
  height: 100%;
  font-family: "mencken-std-head-narrow", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: rgb(76, 53, 42);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.full-screen-height {
  height: var(--screen-height);
}

.full-screen-height-minus-secondary-logo {
  height: calc(var(--screen-height) - 180px);
}

.full-screen-height-minus-menu-bar {
  height: calc(var(--screen-height) - 70px);
}

.full-screen-30 {
  height: calc(var(--screen-height) * 0.3);
}

.full-screen-width {
  width: var(--screen-width);
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bingo-board-cell {
  width: var(--cell-size);
  height: var(--cell-size);
  max-width: var(--cell-size);
  max-height: var(--cell-size);
  min-width: var(--cell-size);
  min-height: var(--cell-size);
  font-size: calc(var(--cell-size) / 7);
  font-family: "hoss-round-xnarrow", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.boardWidth {
  width: var(--board-width);
  min-width: var(--board-width);
  max-width: var(--board-width);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  border: 0;
  outline: 0;
}

input:focus {
  outline: none !important;
}

.input {
  outline: 0;
  resize: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
}

.input:focus {
  outline: none !important;
}

::-webkit-inner-spin-button {
  display: none;
}
::-webkit-calendar-picker-indicator {
  display: none;
}
::-webkit-datetime-edit-text {
  text-align: start;
}

::-webkit-scrollbar {
  display: none;
}

.dialog-background {
  overflow: inherit !important;
}

@keyframes change-homepage-slogan {
  0% {
    content: "GAMIFYING THE GAME";
  }
  33.33% {
    content: "Putting the data in dating...jk";
  }
  66.66% {
    content: "Dating as a means to an end is at its end!!";
  }
  100% {
    content: "Do it for the squares";
  }
}

.homepage-slogan::after {
  display: block;
  content: "Don't hate the player, love the game!";
  animation: change-homepage-slogan 40s infinite;
}

.elementToFadeInAndOut {
  animation: fadeInOut 4s linear 1 forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.elemFadeIn {
  animation: fadeInOut 1s infinite;
}
